<template>

    <div class="social">

        <div class="insta-title">
            <icon icon="share-2"></icon>
            <div class="info">
                <span>Social feed</span>
                <span>#kopaoniklive</span>
            </div>

        </div>
        <div class="splide" ref="main">
            <div class="splide__track">
                <div class="splide__list">
                    <div class="splide__slide" v-for="item in content">
                        <a :href="item.url" target="_blank" class="slide">
                            <img :src="item.image_url"/>
                            <div class="desc">
                                <span class="date">{{item.time}}</span>
                                <p>{{item.description}}</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>

    import Splide from '@splidejs/splide';

    export default {
        props: {
            content: {
                required: true,
                type: Object,
            }
        },

        mounted(){

            new Splide( this.$refs.main, {
                type: 'loop',
                perPage: 2,
                gap: 15
            } ).mount();

        }
    }


</script>

<style lang="scss" >

    @import "../scss/includes";

    .social{

        margin: 0 15px;
        position: relative;

        .insta-title{
            display: flex;
            align-items: center;
            margin-left: 10px;
            margin-bottom: 10px;
            .info{

                margin-left: 10px;


                span{
                    display: block;
                    line-height: 1.3em;

                    &:first-child{
                        text-transform: uppercase;
                        font-size: 0.7em;
                        font-weight: bold;
                    }
                    &:last-child{
                        font-size: 0.9em;
                    }
                }
            }
        }

        .splide{

            .slide{

                border-radius: 5px;
                overflow: hidden;
                margin-bottom: 5px;


                .desc{
                    padding: 10px;
                    font-size: 0.8em;
                    padding-bottom: 35px;
                    background: #efefef;

                    .date{
                        display: block;
                        height: 24px;
                        opacity: 0.5;
                        font-weight: 600;
                    }

                    p{
                        overflow: hidden;
                        height: 60px;
                        line-height: 20px;
                    }
                }
            }



            img{
                display: block;
                width: 100%;
                height: 250px;
                object-fit: cover;
                object-position: center;
            }


        }

        .splide__pagination{

            position: absolute;
            bottom: auto;
            top: -33px;
            right: -10px;
            left: auto;

            .splide__pagination__page{
                background: lighten(color(1, "red"), 20%);

                &.is-active{
                    background: color(2, "red");
                    opacity: 1;
                }

            }

        }

        .splide__arrows button{
            background: rgba(255,255,255,0.2);
            top: 34%;

            svg{
                fill: white;
                width: 10px;
            }
        }


    }

</style>














