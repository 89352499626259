<template>

<div class="weather-container">
    <div class="weather" v-if="$store.state.weatherData">

        <div class="item">
            <div class="value">{{$store.state.weatherData.station.props['Temperatura']}}</div>
            <label>temp.</label>
        </div>
        <div class="item">
            <div class="value">{{$store.state.weatherData.station.props['Opis vremena']}}</div>
            <label>vreme</label>
        </div>
        <div class="item">
            <div class="value">{{$store.state.weatherData.station.props['Vlažnost'].replace(' ', '')}}</div>
            <label>vlaž. vazd.</label>
        </div>
        <div class="item" v-if="$store.state.weatherData.snow.snow">
            <div class="value">{{$store.state.weatherData.snow.snow}}</div>
            <label>vis. snega</label>
        </div>

    </div>
</div>

</template>

<script>




</script>

<style lang="scss" scoped>

    @import "../scss/includes";

    .weather-container{
        height: 50px;
    }

    .weather{
        display: table;
        table-layout: fixed;
        width: 100%;


        .item{

            display: table-cell;
            box-sizing: border-box;
            vertical-align: top;
            padding: 5px 5px;
            line-height: 1;
            text-align: center;

            width: 25%;

            &:not(:last-child){
                border-right: 1px solid rgba(0,0,0,0.1);
            }

            label{
                font-size: 0.75em;
                display: block;
                text-align: center;
            }

            .value{
                font-weight: 800;
                font-size: 1.0em;
                margin-bottom: 2px;
                display: block;
                text-align: center;
            }



        }
    }


</style>
