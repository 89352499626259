<template>

    <div id="page">
        <my-header/>

        <div class="page-content">

            <div class="container np">

                <weather></weather>

                
                <div class="cameras" v-if="$store.state.cameras">

                    <div v-for="(contentItem, index) in content" :key="'ci' + index">

                        <camera :key="'c-' + index" v-if='contentItem.contentType == "camera"' class="camera" :camera="contentItem.data"></camera>

                        <social-image-group v-if='contentItem.contentType == "social"' class="camera" :content="contentItem.data"></social-image-group>

                        <com-group v-if='contentItem.contentType == "com"' :content="contentItem.data"></com-group>

                    </div>

                </div>

            </div>

        </div>


    </div>

</template>

<script>

    import axios from 'axios'
    import helpers from "../helpers";

    export default {

        data() {
            return {
                content: []
            }
        },

        methods: {

            getComByIndex( index ){

                if(!this.$store.state.com)
                    return null;

                return this.$store.state.com[index];

            },

            prepareContent() {

                let cameras = JSON.parse(JSON.stringify(this.$store.state.cameras)).map((d) => {
                    return {
                        contentType: 'camera',
                        data: d,
                    }
                })


                let socialGrouped = [];
                let filtered = this.$store.state.socialFeed.filter((d) => {
                    return d.type == 'image';
                })

                for (let i = 0; i < filtered.length; i++) {

                    let group = Math.floor(i / 10);

                    if (!socialGrouped[group])
                        socialGrouped[group] = []

                    socialGrouped[group].push(JSON.parse(JSON.stringify(filtered[i])))

                }

                let socialFeed = socialGrouped.map((d) => {
                    return {
                        contentType: 'social',
                        data: helpers.shuffleArray(d),
                    }
                })

                let content = [];

                for(let i = 0; i < cameras.length; i++){

                    content.push(cameras[i]);
                    if(i == 3 && socialFeed[0]) content.push( socialFeed[0] );

                }

                console.log(this.$store.state.com)

                // Comm
                for (let i = content.length; i >= 0; i--) {

                    if(this.$store.state.com)
                        for (let j = 0; j < this.$store.state.com.length; j++) {

                            if(this.$store.state.com[j].order == i){
                                content.splice(i, 0, {
                                    contentType: 'com',
                                    data: this.$store.state.com[j]
                                });
                            }
                
                        }
               
                }

                this.content = content

            },
        },


        beforeMount() {

            this.prepareContent();

        },


        mounted() {


        }

    }

</script>

<style lang="scss" scoped>

    .cameras {
        margin-top: 40px;
        padding-bottom: 70px;

        .camera {
            margin-top: 20px;
        }

    }


</style>
