<template>

    <div id="page">
        <my-header/>

        <div class="page-content">

            <div class="container np">

                <div class="three-container">
                    <div class="el gradient-preload"></div>
                    <div class="el gradient-preload"></div>
                    <div class="el gradient-preload"></div>
                </div>
                <div class="elements">
                    <div class="el">
                        <div class="camera-title"></div>
                        <div class="camera-video"></div>
                    </div>
                    <div class="el">
                        <div class="camera-title"></div>
                        <div class="camera-video"></div>
                    </div>
                    <div class="el">
                        <div class="camera-title"></div>
                        <div class="camera-video"></div>
                    </div>
                </div>

            </div>

        </div>


    </div>

</template>

<script>

    import axios from 'axios'
    import helpers from "../helpers";

    export default {

        data() {
            return {
                content: []
            }
        },

        methods: {

        },


        beforeMount() {

        },


        mounted() {


        }

    }

</script>

<style lang="scss" scoped>

    @import "../scss/includes";

    .three-container {

        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 80%;
        margin: 0 auto;

        .el{
            width: 27%;
            height: 50px;
            border-radius: 10px;
        }



    }

    .elements{
        margin-top: 40px;
        .el{
            margin-top: 20px;

            .camera-title{
                height: 20px;
                margin-bottom: 10px;
                width: 200px;
                margin-left: 20px;
                border-radius: 10px;
                @extend %gradient-preload;
            }

            .camera-video{
                width: 100%;
                padding-top: 56.25%;
                position: relative;

                &::before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    content: "";
                    @extend %gradient-preload;
                }

            }


            &:nth-child(2){
                opacity: 0.3;
            }
            &:nth-child(3){
                opacity: 0.1;
            }

        }
    }

</style>
