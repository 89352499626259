import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Privacy from '../views/Privacy.vue'

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/privacy-policy',
    component: Privacy
  }
] 

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
