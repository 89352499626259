<template>

    <div id="header" :class="{ floating: floating, app: $store.state.isApp }">

        <div class="ribbon"></div>

        <div class="container">
            <div class="wrap">

                <div class="left menu-toggle">
                    <div class="btn">
                        <icon icon="menu"></icon>
                    </div>
                </div>

                <div class="right social">
                    <span>{{ time }}</span>
                </div>

                <router-link to="/" class="logo">
                    <img src="../assets/img/logo.svg" alt="">
                </router-link>

            </div>
        </div>

    </div>

</template>

<script>


export default {

    data() {
        return {
            time: '00:00',
            floating: false,
        }
    },

    methods: {
        timeNow() {

            var d = new Date(),
                h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
                m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();

            return h + ':' + m;
        }
    },

    mounted() {

        document.addEventListener('scroll', (e) => {
            if (window.scrollY > 10) {
                this.floating = true;
            }
            else {
                this.floating = false;
            }
        });

        setInterval(() => {
            this.time = this.timeNow()
        }, 1000)

    }

}


</script>

<style lang="scss" scoped>
@import "../scss/includes";

#header {
    width: 100%;
    height: $header-height;
    top: 0;
    position: fixed;
    z-index: 20;

    &.app,
    &.floating {

        background-color: white;

        &::after{
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 30px;
            z-index: 10;
            background: url("../assets/img/shadow.png");
            background-size: auto 100%;
            content: "";
        }

    }

    .ribbon {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 7px;
        @include primary-gradient-bg()
    }

    &.app .ribbon{
        display: none;
    }

    .wrap {
        display: flex;
        padding-top: 30px;
        position: relative;

        .left,
        .right {
            position: absolute;
            top: 32px;
        }

        .left {
            left: 0px;

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                font-size: 20px;
                margin-left: -10px;
                margin-top: -10px;
            }

        }

        .right {
            right: 0;

            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: 600;
            font-size: 0.80em;

            span {
                display: block;

                &:first-child {
                    margin-bottom: -3px;
                    font-weight: 700;
                }

                &:last-child {
                    margin-bottom: -3px;
                }
            }

        }

        .logo {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 120px;
            }

        }

    }

}
</style>
