<template>

    <div class="com-wrap">

        <a 
            v-if="banner"
            target="_blank"
            class="com" 
            :href="banner.url" >
            <img :src='banner.banner' alt="">
        </a>


    </div>

</template>

<script>


    export default {

        data: () => {
            return {
                index: -1,
            }
        },

        computed:{

            banner(){
                if(this.index < 0) return null;
                return this.content.banners[this.index]
            }

        },

        props: {
            content: {
                required: true,
                type: Object,
            }
        },

        methods: {

            /**
             * 
             */
            run(){

                if(this.index == -1){
                    this.index = 0;
                }
                else if(this.index >= this.content.banners.length - 1){
                    this.index = 0;
                } else{
                    this.index++;
                }

                setTimeout( () => {
                    this.run()
                }, this.content.banners[this.index].time * 1000 )

            }

        },

        mounted(){

            this.run();

        }
    }


</script>

<style scoped lang="scss" >

    @import "../scss/includes";

    .com{
        width: 100%;
        img{
            width: 100%;
            display: block;
            margin: 20px 0;
        }
    }

</style>














