import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import settings from "./settings";

import helpersMixin from './mixins/helpers'

// Global SCSS
import './scss/style.scss'
import axios from "axios";
import helpers from "@/helpers";



// Create App
let vueApp = createApp(App)

vueApp.use(store)
    .use(router)
    .mixin(helpersMixin)

axios.defaults.baseURL = settings.baseUrl;
axios.defaults.auth = {
    username: 'k22',
    password: 'k22'
}

store.commit('resetTimestamp')

setInterval( () => {
    store.commit('resetTimestamp')
}, 1000 * 10 * 60 );


document.addEventListener("deviceready", () => {

    StatusBar.backgroundColorByHexString('#eff8fa');
    NavigationBar.backgroundColorByHexString('#eff8fa');
    NavigationBar.show();

});

router.afterEach( (to) => {

    if(to.query.app){
        store.state.isApp = true;
    } else{
        store.state.isApp = false;
    }

} )

let initialize = async () => {

    

    vueApp.mount('#app')

    if(navigator.splashscreen)
        navigator.splashscreen.hide();

    let promises = [];

    promises.push(store.dispatch('getWeatherData'))
    promises.push(store.dispatch('getSocialData'))
    promises.push(store.dispatch('getCameraData'))
    promises.push(store.dispatch('getComData'))

    await Promise.all(promises);

    try{

        let preloadPromises = [];

        // First three
        preloadPromises = preloadPromises.concat(
            helpers.preloadImages(store.state.cameras.map( (c) => {
                return helpers.urlWithTimestamp(c.image_url)
            } ).filter(c => c).slice(0, 3))
        )

        // First two videos
        preloadPromises = preloadPromises.concat(
            helpers.preloadVideos(store.state.cameras.map( (c) => {
                return helpers.urlWithTimestamp(c.video_url_short)
            } ).filter(c => c).slice(0, 2))
        )

        // start time
        let startTime = (new Date()).getTime();

        await Promise.all(preloadPromises);

        // end time
        let endTime = (new Date()).getTime();

        // calculate time difference
        let timeDiff = endTime - startTime;

        console.log('Preload time: ' + timeDiff + 'ms')

        store.state.imagesLoaded = true;
        store.state.foldVideosLoaded = true;

    }
    catch (e){

        store.state.imagesLoaded = true;
        store.state.foldVideosLoaded = true;

    }

}

vueApp.component('icon', require('./components/Icon.vue').default)
vueApp.component('my-header', require('./components/Header.vue').default)
vueApp.component('weather', require('./components/Weather.vue').default)
vueApp.component('camera', require('./components/Camera.vue').default)
vueApp.component('social-image-group', require('./components/SocialImageGroup.vue').default)
vueApp.component('spinner', require('./components/Spinner.vue').default)
vueApp.component('com-group', require('./components/ComGroup.vue').default)


initialize();

