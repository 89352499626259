<template>

    <div id="page">
        <my-header/>

        <div class="page-content">

            <div class="container np">

                <div id="content">

                    <h1>Privacy policy</h1>

                    <p>Effective date: January 30, 2023</p>


                    <p>Kopaonik Live ("us", "we", or "our") operates the Kopaonik Live mobile application (hereinafter referred to as the "Service").</p>

                    <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

                    <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>

                    <h2>Information Collection And Use</h2>


                    <h4>Personal Data</h4>

                    <p>We <b>do not collect any personal information</b>, contact or identity information .</p>

                    <h4>Usage Data</h4>

                    <p>When you access the Service with a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data ("Usage Data").</p>

                    <h4>Tracking &amp; Cookies Data</h4>
                    <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                    <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>

                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>

                    <h4>Analytic data</h4>

                    <p>
                        The Website uses a tool called “Google Analytics” to collect information about use of the Website. Google Analytics collects information such as how often users visit this Website, what pages they visit when they do so, and what other websites they used prior to coming to this Website. We use the information we get from Google Analytics to maintain and improve the Website and our products. We do not combine the information collected through the use of Google Analytics with Personal Information we collect. Google's ability to use and share information collected by Google Analytics about your visits to this Website is restricted by the Google Analytics Terms of Service, available at https://marketingplatform.google.com/about/analytics/terms/us/, and the Google Privacy Policy, available at http://www.google.com/policies/privacy/. You may learn more about how Google collects and processes data specifically in connection with Google Analytics at http://www.google.com/policies/privacy/partners/.
                    </p>

                    <h2>Use of Data</h2>

                    <p>KopaonikLive uses the collected data for various purposes:</p>
                    <ul>
                        <li>To provide and maintain the Service</li>
                        <li>To provide customer care and support</li>
                        <li>To provide analysis or valuable information so that we can improve the Service</li>
                        <li>To monitor the usage of the Service</li>
                        <li>To detect, prevent and address technical issues</li>
                    </ul>

                    <h2>Transfer Of Data</h2>

                    <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>

                    <p>If you are located outside Serbia and choose to provide information to us, please note that we transfer the data, including Personal Data, to Serbia and process it there.</p>

                    <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

                    <p>KopaonikLive will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

                    <h2>Security Of Data</h2>
                    <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                    <h2>Service Providers</h2>
                    <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
                    <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                    <h2>Links To Other Sites</h2>
                    <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

                    <h2>Changes To This Privacy Policy</h2>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                    <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
                    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>


                    <h2>Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, please contact us:</p>
                    <ul>
                        <li>By email: info@kopaonik.live</li>

                    </ul>
                    </div>

            </div>

        </div>


    </div>

</template>

<script>

    export default {

        data() {
            return {
            }
        },

        methods: {

        },


        beforeMount() {

        },


        mounted() {


        }

    }

</script>

<style lang="scss" scoped>

h1{
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 70px;
}

h4{
    font-weight: 600;
    font-size: 1.3em;
    margin: 20px 0;
}

h2{
    font-weight: 600;
    font-size: 1.6em;
    margin: 40px 0;
}

p{
    margin: 20px 0;
}

b{
    font-weight: 600;
}

.container{
    width: 60%;
}

#content{
    padding-top: 70px;
}

ul{
    list-style: disc;
    margin-left: 40px;
    margin-bottom: 40px;

    li{
        margin-bottom: 10px;
    }

}

</style>
