<template>
  <div id="main" :class="{app: $store.state.isApp}" >
    <router-view v-if="loaded"/>
    <preload v-else/>
  </div>
</template>


<script>

import { onBeforeMount } from 'vue';
import Preload from './views/Preload'

export default {

    data(){
      return {
      }
    },

    components: {
        preload: Preload
    },

    computed: {
        loaded() {
            return this.$store.getters.resourcesLoaded
        }
    },

    mounted(){

    }

}

</script>