import { createStore } from 'vuex'
import axios from "axios";

export default createStore({
  state: {

    weatherData: null,
    socialFeed: null,
    cameras: null,
    com: null,
    imagesLoaded: null,
    foldVideosLoaded: null,

    cacheTimestamp: null,
    paddingTop: 0,
    isApp: false,

  },
  getters: {

    /**
     *
     * @param state
     * @returns {boolean}
     */
    resourcesLoaded(state){
      return state.weatherData !== null &&
          state.socialFeed !== null &&
          state.cameras !== null &&
          state.imagesLoaded !== null &&
          state.foldVideosLoaded !== null
    }

  },
  mutations: {

    /**
     *
     * @param state
     */
    resetTimestamp (state){

      let d = new Date();
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      let hour = d.getHours();
      let minute = d.getMinutes();
      
      state.cacheTimestamp = '' + day + month + year + hour + minute;

    }

  },
  actions: {

    /**
     *
     * @param context
     * @returns {Promise<void>}
     */
    async getWeatherData (context){

      let response = await axios.get('/weather')

      context.state.weatherData = response.data

    },

    /**
     *
     * @param context
     * @returns {Promise<void>}
     */
    async getSocialData (context){

      let response = await axios.get('/social-feed')

      context.state.socialFeed = response.data || [];

    },

    /**
     *
     * @param context
     * @returns {Promise<void>}
     */
    async getCameraData (context){

      let response = await axios.get('/cameras')

      context.state.cameras = response.data;

    },

    /**
     *
     * @param context
     * @returns {Promise<void>}
     */
    async getComData (context){

      let response = await axios.get('/com-bnrs')

      context.state.com = response.data;

    },


  },
  modules: {
  }
})
